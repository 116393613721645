<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ SATIN ALMA
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ SATIN ALMA" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-4">
							<div class="field p-fluid">
								<label for="ekipmanAdi">Ekipman Adı <span style="color:red">*</span></label>
								<InputText id="ekipmanAdi" type="text" v-model="mainData.bm_name" :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-1">
							<div class="field p-fluid">
								<label for="adet">Adet <span style="color:red">*</span></label>
								<InputNumber id="adet" v-model="mainData.bm_adet" mode="decimal" locale="tr-TR" :minFractionDigits="0" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_adet.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_adet.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bm_satinalmasorumlusu" v-model="mainData.bm_satinalmasorumlusuName" ref="entity_bm_satinalmasorumlusu"
									label="Satın Alma Sorumlusu" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDevreDisi"
									@itemSelected="bm_satinalmasorumlusuSelected = $event" @itemCleared="bm_satinalmasorumlusuSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_ilerlemedurumu">İlerleme Durumu</label>
								<Dropdown id="bm_ilerlemedurumu" v-model="mainData.bm_ilerlemedurumu" :options="SM_bm_ilerlemedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="İlerleme Durumu" :showClear="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_satinalmadurumu">Satın Alma Durumu</label>
								<Dropdown id="bm_satinalmadurumu" v-model="mainData.bm_satinalmadurumu" :options="SM_bm_satinalmadurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Satın Alma Durumu" :showClear="true" :disabled="isDevreDisi" />
							</div>
						</div>
						
						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" :disabled="isDevreDisi" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>TARİH BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_siparistarihi">Sipariş Tarihi</label>
								<Calendar showButtonBar id="bm_siparistarihi" v-model="mainData.bm_siparistarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_termintarihi">Termin Tarihi</label>
								<Calendar showButtonBar id="bm_termintarihi" v-model="mainData.bm_termintarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_teslimtarihi">Teslim Tarihi</label>
								<Calendar showButtonBar id="bm_teslimtarihi" v-model="mainData.bm_teslimtarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_xgunoncehatirlat">x Gün Önce Hatırlat</label>
								<Dropdown id="bm_xgunoncehatirlat" v-model="mainData.bm_xgunoncehatirlat" :options="SM_bm_xgunoncehatirlat" optionLabel="Value" optionValue="AttributeValue" placeholder="x Gün Önce Hatırlat" :showClear="true" :disabled="isDevreDisi" />
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10054,
			ENTITY_NAME: 'bm_otomasyonsatinalma',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-check-circle',
					visible: () => !this.isDevreDisi,
					command: () => { this.OnDevreDisiBirak(); },
				},
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDevreDisi,
					command: () => { this.OnYenidenEtkinlestirItem(); },
                },
			],
		}
	},
	created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDevreDisi: function() {
			if (this.isYazmaYetkisiVarmi == false) {
				return true;
			}

			if (this.mainData["statecode"]) {
				if (this.mainData["statecode"] == 1) {
					return true;
				}
			}

			return false;
		},
		isYazmaYetkisiVarmi: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == true;
					}
				}
			}

			return false;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Otomasyon Projesi');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		bm_satinalmasorumlusuSelected: {
			get: function () {
				if (this.mainData["bm_satinalmasorumlusuId"]) {
					return { "Value": this.mainData["bm_satinalmasorumlusuId"], "Name": this.mainData["bm_satinalmasorumlusuName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_satinalmasorumlusuId"] = null;
					this.mainData["bm_satinalmasorumlusuName"] = null;
				}
				else {
					this.mainData["bm_satinalmasorumlusuId"] = newValue.Value;
					this.mainData["bm_satinalmasorumlusuName"] = newValue.Name;
				}
			}
		},
		SM_bm_satinalmadurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_satinalmadurumu');
		},
		SM_bm_ilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ilerlemedurumu');
		},
		SM_bm_xgunoncehatirlat: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_xgunoncehatirlat');
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getOtomasyonSatinAlmaById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];

						setTimeout(() => {
							if (this.mainData["bm_satinalmasorumlusuId"]) {
								this.$refs.entity_bm_satinalmasorumlusu.setDefaultData({ "Value": this.mainData["bm_satinalmasorumlusuId"], "Name": this.mainData["bm_satinalmasorumlusuName"] });
							}
						}, 1000);

						if (this.mainData["bm_siparistarihi"]) {
							this.mainData["bm_siparistarihi"] = new Date(this.mainData.bm_siparistarihi);
						}

						if (this.mainData["bm_termintarihi"]) {
							this.mainData["bm_termintarihi"] = new Date(this.mainData.bm_termintarihi);
						}

						if (this.mainData["bm_teslimtarihi"]) {
							this.mainData["bm_teslimtarihi"] = new Date(this.mainData.bm_teslimtarihi);
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getOtomasyonSatinAlmaUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem(this.ENTITY_NAME, this.entityId);
        },
		OnDevreDisiBirak() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		OnYenidenEtkinlestirItem() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 0,
				statusCode: 1
			};

			this.yenidenEtkinlestirItem(postData);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_name: {
					required : helpers.withMessage('Ekipman Adı alanı dolu olmalıdır!', required),
				},
				bm_adet: {
					required : helpers.withMessage('Adet alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
